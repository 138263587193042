import React from 'react'
import './PrePrompt.css'

function PrePrompt({prompt, setMsg, setIsPromptClick}) {
  return (
    <div onClick={() => {setMsg(prompt.text); setIsPromptClick(true)}} className='preprompt_v3'>
        {prompt?.icon}
        <div className='preprompt_v3__text'>{prompt.text}</div>
    </div>
  )
}

export default PrePrompt