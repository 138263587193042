import React from 'react'
import './MsgTab.css'
import { useNavigate } from 'react-router-dom'

function MsgTab({id, title, isActive }) {
    const navigate = useNavigate();
    return (
        <div onClick={()=> navigate("/copilot?chat=" + id)} className={`msgtab_v3 ${isActive && "msgtab_v3__active"}`}>
            <div className='truncate'>{title}</div>
        </div>
    )
}

export default MsgTab