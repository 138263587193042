import React from 'react'
import "./UnlockPro.css"
import { DiamondOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

function UnlockPro({ isShrink = false }) {

    const navigate = useNavigate();

    return (
        <div className='unlockpro_v3'>
            <div className='unlockpro_v3__header'>
                {!isShrink && <div className='unlockpro_v3_title'>Try IQLand Pro</div>}
                <DiamondOutlined />
            </div>
            {
                !isShrink && <div className='unlockpro_v3__content'>
                    Unlock Parcel subdivisions, Cost estimation, and more features.
                </div>
            }
            {
                !isShrink && <div className='unlockpro_v3__button' onClick={() => navigate("/pricing")}>Learn More</div>
            }
        </div>
    )
}

export default UnlockPro