import { Attachment, Close, Send } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import './ChatBar.css'
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner';
import GoogleAddressInput from '../../../components/google_map/GoogleAddressInput';

function ChatBar({ msg, setMsg, handleSendButtonClick, msgLoading, isPromptClick, setIsPromptClick }) {

    const textareaRef = useRef(null);
    const [address, setAddress] = useState("");

    const handleCloseClick = () => {
        setIsPromptClick(false);
        setAddress("");
        setMsg("");
    }

    const sendButtonClick = (msg) => {
        if (msg.trim() === "") return;
        if (address && address.trim() !== "") {
            msg = msg + " at " + address;
            handleSendButtonClick(msg);
            setMsg("");
            setAddress("")
        }
        else{
            handleSendButtonClick(msg);
            setMsg("");
            setAddress("")
    
        }
    }

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`
    }, [msg])

    return (
        <div className='chatbar_v3' style={isPromptClick ? { backgroundColor: "var(--v3-medium-gray-clr)" } : {}}>
            {
                isPromptClick &&
                <div className='chatbar_v3__google_address'>
                    <GoogleAddressInput address={address} setAddress={setAddress} label={"Address"} />
                </div>
            }
            {
                isPromptClick &&
                <div className='chatbar_v3__close' onClick={handleCloseClick}><Close sx={{ fontSize: "0.8rem" }} /></div>
            }
            <div className='chatbar_v3__attachment'><Attachment className='chabar_v3__attachment_icon'/></div>
            <textarea
                className='chatbar_v3__textarea'
                ref={textareaRef}
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Ask a question to Copilot..."
                onKeyDown={(e) => {
                    if (isPromptClick) return
                    if(msg?.trim() === "") return;
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendButtonClick(msg);
                    }
                }}
                disabled={msgLoading || isPromptClick}
                style={isPromptClick ? { backgroundColor: "var(--v3-medium-gray-clr)" } : {}}


            />
            <div className='chatbar_v3__send' onClick={() => sendButtonClick(msg)}>
                {
                    msgLoading ? <IQSpinner /> : <Send className='chatbar_v3__send__icon' />
                }
            </div>

        </div>
    )
}

export default ChatBar