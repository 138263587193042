import React, { useState } from 'react'
import "./NewChatTulsa.css"
import PrePrompt from '../../../copilot_v3/components/PrePrompt';

function NewChatTulsa({ setInput, selectedPrompt, setMsg }) {

    const msg = "Enter your address or parcel ID to access the City of Tulsa's database of pre-reviewed development plans, available at no cost to developers. Only plans aligned with your parcel's zoning entitlements will be recommended, ensuring compliance with local regulations and expediting the approval process."
    const pre_prompts = [
        { text: "Check Zoning Rules" },
        { text: "Apply for a Permit" },
        { text: "Approved Plans Program" },
        { text: "Spot Zoning/Variances" },
        { text: "Grant Finder" },
        { text: "View Master Plan" }
    ];

    const [isPromptClick, setIsPromptClick] = useState(false);
    return (
        <div className='copilot_tulsa_new_chat' style={{height:"80%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <div className='copilot_tulsa_new_chat__logo_area'>
                <img src="/images/tuskegee/logo_tus.png" alt='iqland' />
                <div className='title'>City of Tuskegee</div>
                <h3>Let's get started</h3>
                <div className='copilot_tulsa_new_chat_bot'>
                    {/* <div ><img className='copilot_tulsa_new_chat_bot_logo' src="/images/copilot_new/iq_profile_image.svg" alt='iqland' /></div> */}
                    {/* <div className='copilot_tulsa_new_chat_instruction'>
                        <div style={{ fontSize: "1rem", fontWeight: "600" }}>How to Use the Tulsa Pre-Approved Plans Portal</div>
                        <div style={{ fontSize: "0.9rem", fontWeight: "400" }}>{msg}</div>
                    </div> */}
                    <div className='newchat_v3__prompts'>
                        {
                            pre_prompts.map((prompt, index) => {
                                return <PrePrompt key={index} prompt={prompt} setMsg={setMsg} setIsPromptClick={setIsPromptClick} />
                            })
                        }
                    </div>

                    
                </div>
            </div>

        </div>
    )
}

export default NewChatTulsa