export const chats = {
    "chat_id": "d3f8a1b2-3c4d-4e6f-8a7b-9b2c3d4e5f6a",
    "title": "E 5th St, Tulsa, OK 74104, USA",
    "address": "E 5th St, Tulsa, OK 74104, USA",
    "messages": [
        {
            "id": "a1b2c3d4-e5f6-7a8b-9c0d-e1f2g3h4i5j6",
            "text": "Zoning type, Setbacks, FAR, Lot Coverage, Height and other zoning parameters for E 5th St, Tulsa, OK 74104",
            "sources": [],
            "sent_by": "User",
            "updated_at": "2024-10-01T10:15:30.123Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null
        },
        {
            "id": "b2c3d4e5-f6a7-8b9c-0d1e-2f3g4h5i6j7k",
            "text": "Sorry, I am not able to process requests for this city yet. Please do not hesitate to contact us to know more about our future deployments.",
            "sources": [
                {
                    "id": "c3d4e5f6-a7b8-9c0d-1e2f-3g4h5i6j7k8l",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "d4e5f6a7-b8c9-0d1e-2f3g-4h5i6j7k8l9m",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=teraunits.com"
                },
                {
                    "id": "e5f6a7b8-c9d0-1e2f-3g4h-5i6j7k8l9m0n",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily s Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "f6a7b8c9-d0e1-2f3g-4h5i-6j7k8l9m0n1o",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                }
            ],
            "sent_by": "Bot",
            "updated_at": "2024-10-01T10:15:30.456Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null,
            "follow_up": [
                {
                    "id": "g7h8i9j0-k1l2-3m4n-5o6p-7q8r9s0t1u2v",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "h8i9j0k1-l2m3-4n5o-6p7q-8r9s0t1u2v3w",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "i9j0k1l2-m3n4-5o6p-7q8r-9s0t1u2v3w4x",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "j0k1l2m3-n4o5-6p7q-8r9s-0t1u2v3w4x5y",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                }
            ]
        },
        {
            "id": "k1l2m3n4-o5p6-7q8r-9s0t-1u2v3w4x5y6z",
            "text": "hi",
            "sources": [],
            "sent_by": "User",
            "updated_at": "2024-11-10T12:34:56.789Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null
        },
        {
            "id": "b2c3d4e5-f6a7-8b9c-0d1e-2f3g4h5i6j7k",
            "text": "Sorry, I am not able to process requests for this city yet. Please do not hesitate to contact us to know more about our future deployments.",
            "sources": [
                {
                    "id": "c3d4e5f6-a7b8-9c0d-1e2f-3g4h5i6j7k8l",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "d4e5f6a7-b8c9-0d1e-2f3g-4h5i6j7k8l9m",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=teraunits.com"
                },
                {
                    "id": "e5f6a7b8-c9d0-1e2f-3g4h-5i6j7k8l9m0n",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily s Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "f6a7b8c9-d0e1-2f3g-4h5i-6j7k8l9m0n1o",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                }
            ],
            "sent_by": "Bot",
            "updated_at": "2024-10-01T10:15:30.456Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null,
            "follow_up": [
                {
                    "id": "g7h8i9j0-k1l2-3m4n-5o6p-7q8r9s0t1u2v",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "h8i9j0k1-l2m3-4n5o-6p7q-8r9s0t1u2v3w",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "i9j0k1l2-m3n4-5o6p-7q8r-9s0t1u2v3w4x",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "j0k1l2m3-n4o5-6p7q-8r9s-0t1u2v3w4x5y",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                }
            ]
        },
        {
            "id": "m3n4o5p6-q7r8-9s0t-1u2v-3w4x5y6z7a8b",
            "text": "hi",
            "sources": [],
            "sent_by": "User",
            "updated_at": "2024-11-15T14:23:45.678Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null
        },
        {
            "id": "b2c3d4e5-f6a7-8b9c-0d1e-2f3g4h5i6j7k",
            "text": "Sorry, I am not able to process requests for this city yet. Please do not hesitate to contact us to know more about our future deployments.",
            "sources": [
                {
                    "id": "c3d4e5f6-a7b8-9c0d-1e2f-3g4h5i6j7k8l",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "d4e5f6a7-b8c9-0d1e-2f3g-4h5i6j7k8l9m",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=teraunits.com"
                },
                {
                    "id": "e5f6a7b8-c9d0-1e2f-3g4h-5i6j7k8l9m0n",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily s Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                },
                {
                    "id": "f6a7b8c9-d0e1-2f3g-4h5i-6j7k8l9m0n1o",
                    "title": "steadily",
                    "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
                    "url": "https://www.tulsacity.org/",
                    "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
                }
            ],
            "sent_by": "Bot",
            "updated_at": "2024-10-01T10:15:30.456Z",
            "map": null,
            "plans": [],
            "remaining_plans_query": null,
            "follow_up": [
                {
                    "id": "g7h8i9j0-k1l2-3m4n-5o6p-7q8r9s0t1u2v",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "h8i9j0k1-l2m3-4n5o-6p7q-8r9s0t1u2v3w",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "i9j0k1l2-m3n4-5o6p-7q8r-9s0t1u2v3w4x",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                },
                {
                    "id": "j0k1l2m3-n4o5-6p7q-8r9s-0t1u2v3w4x5y",
                    "text": "What are the best uses for a 30x25 feet space in Tulsa"
                }
            ]
        },
    ]
}


export const sources = [
    {
        "id": "c3d4e5f6-a7b8-9c0d-1e2f-3g4h5i6j7k8l",
        "title": "steadily",
        "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
        "url": "https://www.tulsacity.org/",
        "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
    },
    {
        "id": "d4e5f6a7-b8c9-0d1e-2f3g-4h5i6j7k8l9m",
        "title": "steadily",
        "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
        "url": "https://www.tulsacity.org/",
        "favicon": "https://www.google.com/s2/favicons?sz=24&domain=teraunits.com"
    },
    {
        "id": "e5f6a7b8-c9d0-1e2f-3g4h-5i6j7k8l9m0n",
        "title": "steadily",
        "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily s Tulsa, OK in 2025 - Steadily",
        "url": "https://www.tulsacity.org/",
        "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
    },
    {
        "id": "f6a7b8c9-d0e1-2f3g-4h5i-6j7k8l9m0n1o",
        "title": "steadily",
        "description": "Residential Zoning Laws and Regulations Tulsa, OK in 2025 - Steadily",
        "url": "https://www.tulsacity.org/",
        "favicon": "https://www.google.com/s2/favicons?sz=24&domain=steadily.com"
    }
]

export const follow_ups = [
    
        {
            "id": "g7h8i9j0-k1l2-3m4n-5o6p-7q8r9s0t1u2v",
            "text": "What are the best uses for a 30x25 feet space in Tulsa"
        },
        {
            "id": "h8i9j0k1-l2m3-4n5o-6p7q-8r9s0t1u2v3w",
            "text": "What are the best uses for a 30x25 feet space in Tulsa"
        },
        {
            "id": "i9j0k1l2-m3n4-5o6p-7q8r-9s0t1u2v3w4x",
            "text": "What are the best uses for a 30x25 feet space in Tulsa"
        },
        {
            "id": "j0k1l2m3-n4o5-6p7q-8r9s-0t1u2v3w4x5y",
            "text": "What are the best uses for a 30x25 feet space in Tulsa"
        }
    
]