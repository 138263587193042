import React, { useEffect, useId, useState } from 'react'
import "./Copilot.css"
import NewChat from './components/NewChat'
import BottomNav from './components/BottomNav'
import SideBar from './components/SideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatsScreen from './components/ChatsScreen';
import ChatBar from './components/ChatBar';
import { getChatById, getProfilePicture, getUserChatHistory, llmRequest } from '../../components/user_service/UserService';
import { Stack } from '@mui/material';


function Copilot() {

    const navigate = useNavigate();


    const [titles, setTitles] = useState([]);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const response = await getUserChatHistory();
                setTitles(response?.data);
            } catch (error) {
                console.error('Error fetching chat history:', error);
            }
        };

        fetchChatHistory();
    }, []);


    // getting current path and id
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const chatID = query.get("chat");

    //temp id
    const temp_id = useId();

    // handle chat text in the chat bar
    const [msg, setMsg] = useState();

    // handle msg loading
    const [msgLoading, setMsgLoading] = useState(false);

    // full chat
    const [fullChat, setFullChat] = useState([]);

    //current chat address
    const [address, setAddress] = useState();

    //current chat title
    const [chatTitle, setChatTitle] = useState();

    // add new item in the history from front
    const handleNewChat = (newChat) => {
        setTitles((prevTitles) => {
            // Check if the newChat already exists
            const existingIndex = prevTitles.findIndex(chat => chat.chat_id === newChat.chat_id);

            if (existingIndex !== -1) {
                // If it exists, remove it from its current position
                const updatedTitles = [...prevTitles];
                updatedTitles.splice(existingIndex, 1); // Remove the existing chat
                return [newChat, ...updatedTitles]; // Add the new chat at the top
            } else {
                // If it doesn't exist, just add it to the top
                return [newChat, ...prevTitles];
            }
        });
    };


    // handle send button click
    const handleSendButtonClick = async (msg) => {
        setMsgLoading(true);

        //avoid multitiple click
        if (msgLoading) return;

        //empty request
        if (!msg) return;
        if (msg.trim() === "") return;

        let message = msg.trim();

        //set empty
        setMsg("");

        // show immedietly user message
        setFullChat(prevChats => [...prevChats, { chat_id: temp_id, sent_by: 'User', text: message }]);

        try {
            // already in chat
            if (chatID) {
                const llm_response = await llmRequest({ user_message: message, chat_id: chatID });
                const bot_response = llm_response.llm_response;

                // if it is new or old chat, need to move that chat
                if (chatID !== llm_response.chat_id) {
                    if (llm_response.new_chat === true) {
                        const date = new Date();
                        const newChat = { chat_id: llm_response.chat_id, chat_title: llm_response.address, updated_at: date.toISOString() };
                        handleNewChat(newChat);
                    }
                    navigate(`/copilot?chat=${llm_response.chat_id}`);
                }
                else {
                    setFullChat(prevChats => [...prevChats, { chat_id: temp_id, sent_by: 'Bot', text: bot_response }]);
                    setMsgLoading(false);
                }

            }
            else {
                const llm_response = await llmRequest({ user_message: msg });

                if (llm_response.new_chat === true) {
                    let chatTitle = llm_response.address ? llm_response.address !== "NA" ? llm_response.address : msg.substring(0, 25) : msg.substring(0, 25);
                    // console.log(chatTitle);
                    const date = new Date();
                    const newChat = { chat_id: llm_response.chat_id, chat_title: chatTitle, updated_at: date.toISOString() };
                    handleNewChat(newChat);
                }

                navigate(`/copilot?chat=${llm_response.chat_id}`, { state: { llm: llm_response } });
            }


        }
        catch (err) {
            console.log(err);
            setMsgLoading(false);
        }


    }

    //fetch chat by id
    useEffect(() => {
        try {
            setMsgLoading(true);
            setFullChat([]);
            async function fetchChat() {
                if (!chatID) {
                    setMsgLoading(false);
                    return;
                }
                const response = await getChatById(chatID);
                setFullChat(response.data.messages);
                setChatTitle(response.data.title);
                setAddress(response.data.address);

                setMsgLoading(false);
            }
            fetchChat();
        }
        catch (err) {
            console.log(err);
            setMsgLoading(false);
        }
    }, [chatID])


    useEffect(() => {
        async function setProfilePicture() {
            try {
                const response = await getProfilePicture();
                const profilePicture = response.presigned_url;
                localStorage.setItem("profilePicture", profilePicture);

            }
            catch (error) {
                console.error(error);
            }
        }
        setProfilePicture();
    }, [])



    return (
        <div className='copilot_v3' >
            <SideBar titles={titles} />
            <div className='copilot_v3__main'>
                <div className='copilot_v3__container'>
                    {
                        !chatID && !msgLoading ? <NewChat msg={msg} setMsg={setMsg} handleSendButtonClick={handleSendButtonClick} />
                            : <ChatsScreen fullChat={fullChat} msgLoading={msgLoading} chatTitle={chatTitle} />
                    }
                    {
                        chatID && <div className='copilot_v3__chatbar_in_chat'>
                            <ChatBar msg={msg} setMsg={setMsg} handleSendButtonClick={handleSendButtonClick} msgLoading={msgLoading} />
                        </div>
                    }
                    <BottomNav />
                </div>
                <div className='copilot_v3__burger'>
                    <Stack/>
                </div>
            </div>
        </div >
    )
}

export default Copilot