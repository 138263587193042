import { Add } from '@mui/icons-material'
import React from 'react'
import './FollowUp.css'

function FollowUp({text}) {
  return (
    <div className='followup_v3'>
        <div className='followup_v3__text'>{text}</div>
        <div><Add fontSize='1rem'/></div>
    </div>
  )
}

export default FollowUp