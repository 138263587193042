import React, { useEffect, useRef } from 'react'
import './ChatsScreen.css'
import SingleChat from './SingleChat'
import { Delete } from '@mui/icons-material'
import ChatLoading from './ChatLoading'
import { Skeleton } from '@mui/material'

function ChatsScreen({ msgLoading, fullChat, chatTitle }) {


    // scroll to bottom
    const endRef = useRef(null);
    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [fullChat]);

    return (
        <div className='chatscreen_v3'>
            <div className='chatscreen_v3__header'>
                {
                    !msgLoading ?
                        <div className='chatscreen_v3__header_title'>Tulsa Project / <span>{chatTitle}</span></div>
                        : <Skeleton variant='text' width={"80%"} />
                }
                <Delete fontSize='0.8rem' className='chatscreen_v3__header_delete' />
            </div>
            {

                fullChat?.map(chat => (
                    <SingleChat key={chat.id} chat={chat} />
                ))
            }
            {
                msgLoading && <ChatLoading />
            }
            <div ref={endRef} style={{ marginBottom: "2.5rem" }}></div>
        </div>
    )
}

export default ChatsScreen