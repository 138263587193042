import React from 'react'
import './SingleChat.css'
import ProfileImage from '../../../components/profile_img/ProfileImage'
import { BugReportOutlined, DashboardCustomize, ForumOutlined, Language, Refresh, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material'
import Source from './Source'
import FollowUp from './FollowUp'
import { follow_ups, sources } from '../sample/SampleChats'
import ChatFormat from '../../../components/chatbot_msg_components/ChatFormat'
import MapWithPolygon from '../../../components/google_map/MapWithPolygon'
import PlanNew from '../../dashboard_new/component/PlanNew'

function SingleChat({ chat }) {



    return (
        <div className='singlechat_v3'>

            {
                chat.sent_by === 'User' ?
                    <div className='singlechat_v3__user_chat'>
                        <div className='singlechat_v3__user'>
                            <div><ProfileImage style={{ width: '2.5rem', height: '2.5rem' }} /></div>
                            <div className='singlechat_v3__user_msg'>
                                {chat.text}
                            </div>
                        </div>
                    </div>
                    :
                    <div className='singlechat_v3__bot_chat'>
                        <div className='singlechat_v3__bot'>
                            {
                                sources.length !== 0 &&
                                <div className='singlechat_v3__sources'>
                                    <div className='singlechat_v3__sub_title'>
                                        <Language sx={{ color: "var(--v3-primary-clr)" }} />
                                        <div className='singlechat_v3__sub_title_text'>Sources</div>
                                    </div>
                                    <div className='singlechat_v3__sources_list'>
                                        {
                                            sources.map(source => (
                                                <Source key={source.id} source={source} />
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className='singlechat_v3__answer'>
                                <div className='singlechat_v3__sub_title'>
                                    <img src='/green_icon.png' alt='IQLand green icon' />
                                    <div className='singlechat_v3__sub_title_text'>Answer</div>
                                </div>
                                <div>

                                    {/* formated texts */}
                                    <div><ChatFormat msg={chat.text} /></div>

                                    {/* recomended plans */}
                                    {
                                        chat.plans && chat.plans.length > 0 &&
                                        <div className='copilot_new__plans_area'>
                                            <h6 className='copilot_new__title'>Plans Found: {chat.plans.length}</h6>
                                            <div className='copilot_new__plans'>
                                                {
                                                    chat.plans.map((plan, index) => (
                                                        <PlanNew key={index} plan={plan} fav={false} selectedPlan={false} selectPlan={() => { }} handleClick={() => { }} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* missing inputs */}
                                    <div></div>

                                    {/* google map */}
                                    {
                                        chat.map && <div className='co_single_chat__map' style={{marginBottom:"2.5rem"}}>
                                            <h3>Map</h3>
                                            <MapWithPolygon polygon={chat.map} /></div>

                                    }

                                    {/* actions on llm response */}
                                    <div className='singlechat_v3__chat_actions'>
                                        <div className='singlechat_v3__populate_smartbuild'>
                                            <DashboardCustomize fontSize='1rem' className='action_icons' />
                                            <div style={{ fontSize: "0.8rem" }}>Populate SmartBuild</div>
                                        </div>
                                        <div className='singlechat_v3__other_actions'>
                                            <Refresh fontSize='1rem' className='action_icons' />
                                            <ThumbUpAltOutlined fontSize='1rem' className='action_icons' />
                                            <ThumbDownAltOutlined fontSize='1rem' className='action_icons' />
                                            <BugReportOutlined fontSize='1rem' className='action_icons' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                follow_ups.length !== 0 &&
                                <div className='singlechat_v3__follow_ups'>
                                    <div className='singlechat_v3__sub_title'>
                                        <ForumOutlined sx={{ color: "var(--v3-primary-clr)" }} />
                                        <div className='singlechat_v3__sub_title_text'>Follow Up</div>
                                    </div>
                                    <div className='singlechat_v3__follow_ups_list'>
                                        {
                                            follow_ups.map(follow_up => (
                                                <FollowUp key={follow_up.id} text={follow_up.text} />
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}

export default SingleChat