import React from 'react'
import './Source.css'

function Source({source}) {
  return (
    <div className='source_v3'>
        <div className='source_v3__description'>
            {source.description}
        </div>
        <div className='source_v3__web'>
            <img src={source.favicon} alt='source logo'/>
            <div className='source_v3__web__name'>{source.title}</div>
        </div>
    </div>
  )
}

export default Source