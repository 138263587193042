import React, { useEffect, useState } from 'react'
import './ProfileTab.css'
import ProfileImage from '../../../components/profile_img/ProfileImage'
import { DiamondOutlined, KeyboardArrowRight } from '@mui/icons-material'
import { getUserSetting } from '../../../components/auth/AuthService';

function ProfileTab({ isPro, onClick, isShrink }) {
    const [name, setName] = useState("Loading...");
    const [lastName, setLastName] = useState("");

    useEffect(() => {
        try {
            getUserSetting().then((response) => {
                const userDetails = response.data;
                setName(userDetails.first_name);
                setLastName(userDetails.last_name);
            })
        }
        catch (err) {
            console.log(err)
        }

    }, [])


    return (
        <div className='v3_profile_tab' onClick={onclick}>
            <div className='v3_profile_tab_content'>
                <ProfileImage style={!isShrink? { width: "2.5rem", height: "2.5rem" }: {width:"1.5rem", height:"1.5rem"}} />
                {
                    !isShrink &&
                        <div>
                            <div className='v3_profile_tab_name'>
                                <div style={{ fontSize: "0.85rem" }}>{`${name} ${lastName !== "NA" ? lastName : ""}`}</div>
                                {isPro && <DiamondOutlined sx={{ color: "var(--v3-secondary-clr)", fontSize: "1rem" }} />}
                            </div>
                            <div className='v3_profile_tab_package'>{!isPro ? "Free Tier" : "IQLand Pro"}</div>
                        </div>
                }
            </div>
            <div>
                <KeyboardArrowRight />
            </div>
        </div>
    )
}

export default ProfileTab