import { format, isAfter, isToday, isYesterday, subDays } from "date-fns";

const today = new Date();
const sevenDaysAgo = subDays(today, 7);
const thirtyDaysAgo = subDays(today, 30);

export const groupChatsByDate = (chats, groupedChats) => {


    chats.forEach((chat) => {
        const chatDate = new Date(chat.updated_at);

        if (isToday(chatDate)) {
            groupedChats.today.push(chat);
        } else if (isYesterday(chatDate)) {
            groupedChats.yesterday.push(chat);
        } else if (isAfter(chatDate, sevenDaysAgo)) {
            groupedChats.previousSevenDays.push(chat);
        } else if (isAfter(chatDate, thirtyDaysAgo)) {
            groupedChats.previousThirtyDays.push(chat);
        } else {
            const month = format(chatDate, 'MMMM');
            const year = format(chatDate, 'yyyy');

            // Group by month
            if (!groupedChats.months[year]) {
                groupedChats.months[year] = {};
            }

            if (!groupedChats.months[year][month]) {
                groupedChats.months[year][month] = [];
            }

            groupedChats.months[year][month].push(chat);

            // Group by year
            if (!groupedChats.years[year]) {
                groupedChats.years[year] = [];
            }

            groupedChats.years[year].push(chat);
        }
    });

    return groupedChats;
};
