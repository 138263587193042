import React, { useState } from 'react'
import ChatBar from './ChatBar'
import './NewChat.css'
import PrePrompt from './PrePrompt'
import BlackIcon from '../../../components/iqland_logo/BlackIcon'
import { Assessment, Home, Map, Payment } from '@mui/icons-material';

function NewChat({ msg, setMsg, handleSendButtonClick, prePrompt, setPrePrompt, address, setAddress }) {

    const pre_prompts = [
        { text: "Create a site plan for a parcel", icon: <Map /> },
        { text: "Recommend a modern home plan for my land.", icon: <Home /> },
        { text: "What does it cost to build a home here?", icon: <Payment /> },
        { text: "What is my home worth today?", icon: <Assessment /> }
    ];

    const [isPromptClick, setIsPromptClick] = useState(false);

    return (
        <div className='newchat_v3'>
            <div className='newchat_v3__header'>
                <BlackIcon style={{ width: '65%' }} />
                <h1 style={{ fontSize: "1.5rem", fontWeight: 300 }}>Let's get started</h1>
            </div>
            <ChatBar msg={msg} setMsg={setMsg} handleSendButtonClick={handleSendButtonClick} isPromptClick={isPromptClick} setIsPromptClick={setIsPromptClick}/>

            <div className='newchat_v3__prompts'>
                {
                    pre_prompts.map((prompt, index) => {
                        return <PrePrompt key={index} prompt={prompt} setMsg={setMsg} prePrompt={prePrompt} setIsPromptClick = {setIsPromptClick}/>
                    })
                }
            </div>

        </div>
    )
}

export default NewChat