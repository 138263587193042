import React from 'react'
import "./Package.css"
import { Done } from '@mui/icons-material'

function Package({ buttonTxt, packageData, isColor }) {


    return (
        <div className='package_v3'>
            <div className='package_v3__top'>
                <div className='package_v3__header'>
                    <div className='package_v3__title'>{packageData.name.toUpperCase()}</div>
                    <div className='package_v3__desc'>{packageData.description}</div>
                </div>
                <div className='package_v3__price_period'>
                    {
                        packageData.prices[0].discount_percentage !== "0%" ?
                            <div className='package_v3__price'>
                                <div style={{textDecoration:"line-through"}}>{`$${packageData.prices[0].unit_amount}`}</div>
                                <div style={{color:"var(--v3-primary-dark-clr"}}>{`$0`}</div>
                            </div>
                            :
                            <div className='package_v3__price'>
                                {
                                    isColor
                                        ? `$${packageData.prices[0].unit_amount}`
                                        : "Free"
                                }
                            </div>
                    }
                    <div className='package_v3__period'>
                        {packageData.prices[0].interval === "month" ? "Monthly" : "Forever"}
                    </div>
                </div>
                <div className='package_v3__button_area'>
                    <div className={isColor ? 'package_v3__button_clr' : 'package_v3__button'}>
                        {buttonTxt}
                    </div>
                </div>
            </div>
            <div className='package_v3__features'>
                {
                    packageData.features.map((feature, index) => {
                        return (
                            <div className='package_v3__feature' key={index}>
                                <Done className='package_v3__feature_icon' style={isColor ? { backgroundColor: "var(--v3-primary-clr)", color: "var(--v3-light-clr)" } : {}} />
                                <div className='package_v3__feature_text'>{feature.name}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Package