import React, { useEffect, useState } from 'react'
import "./Pricing.css"
import Logo from '../../components/iqland_logo/Logo'
import Package from './components/Package'
import { currentPackage, sample_package } from './data/SampleData'
import { getUserChatHistory } from '../../components/user_service/UserService'
import SideBar from '../copilot_v3/components/SideBar'
import BottomNav from '../copilot_v3/components/BottomNav'
import { Stack } from '@mui/material'

function Pricing() {

    const [titles, setTitles] = useState([]);
    
        useEffect(() => {
            const fetchChatHistory = async () => {
                try {
                    const response = await getUserChatHistory();
                    setTitles(response?.data);
                } catch (error) {
                    console.error('Error fetching chat history:', error);
                }
            };
    
            fetchChatHistory();
        }, []);



    return (
        <div className='copilot_v3' >
            <SideBar titles={titles} />
            <div className='copilot_v3__main'>
                <div className='copilot_v3__container' >
                    <div className='pricing_v3'>
                        <div className='pricing_v3__header'>
                            <Logo width={"200"} />
                            <div className='pricing_v3__title'>RESIDENTIAL</div>
                        </div>
                        <div className='pricing_v3__desc'>
                            IQLand Residential is the most powerful way to search the internet with unlimited Pro Search,
                            upgraded AI models, unlimited file upload, image generation, custom knowledge hubs,
                            and collaborative spaces.
                        </div>
                        <div className='pricing_v3__packages'>
                            <Package buttonTxt={"Continue with Free"} packageData={sample_package} isColor={false} />
                            <Package buttonTxt={"Continue with RESIDENTIAL"} packageData={currentPackage} isColor={true} />
                        </div>
                    </div>
                    <BottomNav />
                </div>
                <div className='copilot_v3__burger'>
                    <Stack />
                </div>
            </div>
        </div >

    )
}

export default Pricing