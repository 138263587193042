import { Skeleton } from '@mui/material'
import React from 'react'

function ChatLoading() {

    return (
        <div>
            <div className='singlechat_v3__user_chat' style={{ width: "100%" }}>
                <div className='singlechat_v3__user'>
                    <div><Skeleton variant="circular" width={"2.5rem"} height={"2.5rem"} /></div>
                    <div className='singlechat_v3__user_msg' style={{ width: "100%" }}><Skeleton width={"100%"} /></div>
                </div>
            </div>
            <div className='singlechat_v3__bot_chat'>
                <div className='singlechat_v3__bot'>
                    {

                        <div className='singlechat_v3__sources'>
                            <div className='singlechat_v3__sub_title'>
                                <Skeleton variant='circular' width={"1rem"} height={"1rem"} />
                                <Skeleton variant='text' width={"5rem"} height={"1rem"} />
                            </div>
                            <div className='singlechat_v3__sources_list'>
                                {
                                    [1, 1, 1, 1].map((source, index) => (
                                        <Skeleton key={index} variant='rectangular' className='source_v3' />
                                    ))
                                }
                            </div>
                        </div>
                    }
                    <div className='singlechat_v3__answer'>
                        <div className='singlechat_v3__sub_title'>
                            <Skeleton variant='circular' width={"1rem"} height={"1rem"} />
                            <Skeleton variant='text' width={"5rem"} height={"1rem"} />
                        </div>
                        <div>
                            <div className='singlechat_v3__user_msg' style={{ width: "100%" }}><Skeleton width={"100%"} /></div>
                            <div className='singlechat_v3__user_msg' style={{ width: "100%" }}><Skeleton width={"100%"} /></div>
                            <div className='singlechat_v3__user_msg' style={{ width: "100%" }}><Skeleton width={"100%"} /></div>
                            <div className='singlechat_v3__user_msg' style={{ width: "100%" }}><Skeleton width={"100%"} /></div>
                            <div className='singlechat_v3__chat_actions'>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ChatLoading