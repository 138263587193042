import React from 'react'
import './BottomNav.css'
import { useNavigate } from 'react-router-dom'

function BottomNav() {

    const navigate = useNavigate();
   
return (
    <div className='bottomnav_v3'>
        <div className='bottomnav_v3__item'>Copilot</div>
        <div className='bottomnav_v3__item'>SmartBuild</div>
        <div className='bottomnav_v3__item'>Appraisal IQ</div>
        <div className='bottomnav_v3__item' onClick={() => navigate("/pricing")}>Pricing</div>
        <div className='bottomnav_v3__item'>FAQs</div>
        <div className='bottomnav_v3__item'>Contact</div>
        <div className='bottomnav_v3__item'>support@iqland.ai</div>
    </div>
)
}

export default BottomNav