import React from 'react'
import './PageTab.css'
import { Add, Remove } from '@mui/icons-material'

function PageTab({ title, icon, active, isExpandable, onClick, isActive, isShrink }) {


    return (
        <div className={`pagetab_v3 ${isActive && "pagetab_v3__active"}`} onClick={onClick? onClick:()=>{}}>
            <div className='pagetab_v3__content'>
                {icon}
                {!isShrink && <div className='pagetab_v3__title' >{title}</div>}
            </div>
            {
                isExpandable && (active ? <Remove sx={{fontSize:"1rem"}}/>: <Add sx={{fontSize:"1rem"}}/>)
            }
        </div>
    )
}

export default PageTab