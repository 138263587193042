import React from 'react'

function Logo({style, width}) {
    return (
        <div onClick={()=> window.location.href="/"} style={{cursor:"pointer", ...style}}>
            <img src='/images/logo.svg' alt='iqland logo icon black' width={width}/>
        </div>
    )
}

export default Logo