import React, { useState, useEffect } from 'react';
import './ProfileImage.css';

function ProfileImage({ style }) {
  const [profileImg, setProfileImg] = useState(localStorage.getItem("profilePicture") || "/images/default_profile.png");

  useEffect(() => {
    const handleStorageChange = () => {
      setProfileImg(localStorage.getItem("profilePicture") || "/images/default_profile.png");
    };

    // Listen for storage updates (if profile is changed in another tab)
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className='v3_profile_img' style={style}>
      <img
        src={profileImg}
        alt="User Profile"
        onError={(e) => e.target.src = "/images/default_profile.png"} // Fallback if the image fails to load
      />
    </div>
  );
}

export default ProfileImage;
