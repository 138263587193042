export const sample_package = {
    "id": "prod_QptgDsCd1EcC5v2",
    "name": "Standard",
    "description": "Start for free, no credit card needed.",
    "features": [
        {
            "name": "Unlimited free searches"
        },
        {
            "name": "3 Pro searches per day"
        },
        {
            "name": "Fast free AI model"
        },
        {
            "name": "Upload 3 files per day"
        }
    ],
    "prices":
    [{
        "id": "price_1QKGHgHaANDwoV9cdvTIDSHC2",
        "currency": "usd",
        "interval": "Forever",
        "discount_percentage": "0%",
        "unit_amount": 0
    }]
}

export const currentPackage =
{
    "id": "prod_QptgDsCd1EcC5v",
    "name": "Residential",
    "description": "Perfect for real estate agents, residential developers, investors and architects.",
    "features": [
        {
            "name": "All the features all the time"
        },
        {
            "name": "Unlimited parcel searches"
        },
        {
            "name" : "Copilot"
        },
        {
            "name" : "SmartBuild"
        },
        {
            "name" : "Appraisal IQ"
        },
        {
            "name" : "SmartMap"
        },
        {
            "name" : "Plan recommendations"
        },
        {
            "name" : "Feasibility reports for new construction projects"
        },
        {
            "name" : "Share reports with stakeholders"
        }

    ],
    "prices":
    [{
        "id": "price_1QKGHgHaANDwoV9cdvTIDSHC",
        "currency": "usd",
        "interval": "month",
        "discount_percentage": "100%",
        "unit_amount": 99
    }]
}