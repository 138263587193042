import React, { useEffect, useState } from 'react'
import { AutoAwesomeMotion, DiamondOutlined, KeyboardDoubleArrowLeft, Public, SettingsOutlined, SpeakerNotes, SupportAgent, Try, Widgets } from '@mui/icons-material';
import Logo from '../../../components/iqland_logo/Logo';
import MsgTab from './MsgTab';
import PageTab from './PageTab';
import './SideBar.css'
import UnlockPro from './UnlockPro';
import ProfileTab from './ProfileTab';
import { useLocation, useNavigate } from 'react-router-dom';
import { groupChatsByDate } from '../service/GroupChats';

function SideBar({ titles, isShrink }) {

    const groupedChats = {
        today: [],
        yesterday: [],
        previousSevenDays: [],
        previousThirtyDays: [],
        months: {},
        years: {},
    };

    groupChatsByDate(titles, groupedChats);

    const showGroupedChat = (title, chats) => {
        return (
            chats?.length !== 0 &&
            <> <div className='copilot_v3__sub_tabs_title'>{title}</div>
                {
                    chats?.map(title => (
                        <MsgTab key={title.chat_id} title={title.chat_title} id={title.chat_id} isActive={chatID === title.chat_id} />
                    ))
                }
            </>
        )
    }

    const isPro = false;

    //consoles, history, projects
    const [isProjects, setIsProjects] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [isConsoles, setIsConsoles] = useState(false);

    // get tab from url
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const chatID = query.get("chat");

    // navigataion
    const navigate = useNavigate();


    useEffect(() => {
        if (chatID) {
            setIsHistory(true);
        }
    }, [chatID])

    const [shrink, setShrink] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setShrink(true);
        }
    }, [])

    return (
        <div className={!shrink ? 'copilot_v3__sidebar' : 'copilot_v3__sidebar_shrink'}>

            <div className='copilot_v3__top'>

                {
                    !shrink ?
                        <div className='copilot_v3__sidebar_header'>
                            <Logo width={"80%"} />
                            <div onClick={() => setShrink(!shrink)}><KeyboardDoubleArrowLeft className='copilot_v3__header_arrow' /></div>
                        </div>
                        :
                        <div className='copilot_v3__sidebar_header'>
                            <img style={{cursor:"pointer"}} onClick={()=> window.location.href="/"} src='/iq_ic.png' alt='IQLand icon'/>
                        </div>
                }

                <div className='copilot_v3__free' onClick={() => setShrink(false)}>
                    <div className='copilot_v3__expandable_tab'>
                        <PageTab title='New Chat' icon={<Try />} isActive={!chatID} onClick={() => navigate("/copilot")} isShrink={shrink} />
                    </div>
                    <div className='copilot_v3__expandable_tab'>
                        <PageTab title='Explore' icon={<Public />} isShrink={shrink} />
                    </div>
                    <div className='copilot_v3__expandable_tab'>
                        <PageTab title='Projects' icon={<Widgets />} isExpandable={!shrink} active={isProjects} onClick={() => setIsProjects(!isProjects)} isShrink={shrink} />
                        {
                            isProjects && !shrink && (
                                <div className='copilot_v3__sub_tabs'>
                                    <div style={{ fontSize: "0.8rem", color: "var(--v3-medium-gray-clr" }}>Empty</div>
                                </div>
                            )
                        }
                    </div>
                    <div className='copilot_v3__expandable_tab'>
                        <PageTab title='History' icon={<SpeakerNotes />} isExpandable={!shrink} active={isHistory} onClick={() => setIsHistory(!isHistory)} isShrink={shrink} />
                        {
                            isHistory && !shrink && (
                                <div className='copilot_v3__sub_tabs'>
                                    {showGroupedChat("Today", groupedChats.today)}
                                    {showGroupedChat("Yesterday", groupedChats.yesterday)}
                                    {showGroupedChat("Previous 7 days", groupedChats.previousSevenDays)}
                                    {showGroupedChat("Previous 30 days", groupedChats.previousThirtyDays)}

                                    {Object.keys(groupedChats.months).map((year) =>
                                        Object.keys(groupedChats.months[year]).map((month) => (
                                            <React.Fragment key={`${year}-${month}`}>
                                                {showGroupedChat(`${year}-${month}`, groupedChats.months[year][month])}
                                            </React.Fragment>
                                        ))
                                    )}
                                    {Object.keys(groupedChats.years).map((year) => (
                                        <React.Fragment key={year}>
                                            {showGroupedChat(`${year}`, groupedChats.years[year])}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )
                        }
                    </div>

                    {

                        isPro && (
                            <>
                                <div className='unlockpro_v3__header' style={{ padding: "1rem 0 0.5rem 0.5rem" }}>
                                    {!shrink && <div className='unlockpro_v3_title'>IQLand Pro</div>}
                                    <DiamondOutlined />
                                </div>
                                <div className='copilot_v3__expandable_tab'>
                                    <PageTab title='Consoles' icon={<AutoAwesomeMotion />} isExpandable={!shrink} active={isConsoles} onClick={() => setIsConsoles(!isConsoles)} isShrink={shrink} />
                                    {
                                        isConsoles && !shrink &&(
                                            <div className='copilot_v3__sub_tabs'>
                                                <MsgTab title='SmartBuild' />
                                                <MsgTab title='SmartMap' />
                                                <MsgTab title='Appraisal IQ' />
                                            </div>
                                        )
                                    }
                                </div>

                            </>
                        )

                    }

                </div>
            </div>
            <div className='copilot_v3__bottom' onClick={() => setShrink(false)}>
                {
                    !isPro && <UnlockPro isShrink={shrink}/>
                }

                <PageTab title='Help' icon={<SupportAgent />} isExpandable={false} active={false} isShrink={shrink} />
                <PageTab title='Settings' icon={<SettingsOutlined />} isExpandable={false} active={false} onClick={() => window.location.href = "/settings/personal"} isShrink={shrink} />
                <ProfileTab isShrink={shrink} isPro={isPro} onClick={() => window.location.href = "/settings/personal"}/>
            </div>

        </div>
    )
}

export default SideBar