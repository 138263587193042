import React from 'react'

function BlackIcon({style}) {
  return (
    <div style={style}>
        <img src='/black_icon.svg' alt='iqland logo icon black'/>
    </div>
  )
}

export default BlackIcon